import { ReactComponent as QrLogo } from '../assets/hovercode.svg';

export default function AnimatedLogo({ onAnimationDone }) {
  
    return( 
          <div className="h-8 font-bold text-4xl text-red flex">
            <div class="w-44"><QrLogo className="h-28 fill-current text-red"/></div>
            <div className='w-36 pt-8'> - Mulle</div>
          </div>  
    );
}