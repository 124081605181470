import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

export default function TableReservation() {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
      } = useForm({ defaultValues: { guests: 1, date: new Date().toISOString().split('T')[0], time: '12:00'}});

      
    const { t, i18n } = useTranslation('common');
    
    const [lang, setLang] = useState('ee');

    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [locationInfo, setLocationInfo] = useState({});
    const [submitOk, setSubmitOk] = useState(false);

    useEffect(() => { 
        const info = async() => {
            //const response = await fetch(`http://localhost:5000/api/public/reservation/${params.locationId}`);
            const response = await fetch(`https://api.mulle.ee/api/public/reservation/${params.locationId}`);
            if (response.status !== 200) {
                console.log('error in fetching location info');
                return;
            }
            const locationResponse = await response.json();
            setLocationInfo(locationResponse);
            setLoading(false);
        }
        info(); 
       
    }, [params.locationId])

    useEffect(() => { 
        const langFromUrl = window.location.href.indexOf('.ng') > -1 ? 'ng' : 'ee';
        setLang(langFromUrl);
        i18n.changeLanguage(langFromUrl); 
    }, [])

    const onSubmit = async(data) => { 
        //console.log('url', `https://api.mulle.ee/api/public/reservation/${params.locationId}?timezoneoffset=${new Date().getTimezoneOffset()}&lang=${lang}`); return;
        const response = await fetch(
            `https://api.mulle.ee/api/public/reservation/${params.locationId}?timezoneoffset=${new Date().getTimezoneOffset()}&lang=${lang}`,
            {
                headers: {'Content-Type': 'application/json',},
                method: 'POST', 
                mode: 'cors', 
                cache: 'no-cache',
                body: JSON.stringify({
                    name: data.name,
                    count: data.guests,
                    email: data.email,
                    phone: data.phone,
                    date: data.date,
                    time: data.time,
                    zoneId: data.zone,
                    comment: data.comment
                })
            });

        if (response.status === 200) {
            setSubmitOk(true);
        }
    }

    const Spinner = ({color}) => {
        return <svg className={"animate-spin -ml-1 mr-3 h-10 w-10 " + (color ? color : 'text-red')} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    }

    return <>
        { locationInfo.companyBackground && locationInfo.companyBackground.length > 0 && <div>
            <img className="object-cover object-top w-full h-64" src={locationInfo.companyBackground} alt='cover'/>
            <div className="max-w-full w-full lg:max-w-4xl">
                <div className="flex-1">
                    <div className="relative" >
                    </div>
                </div>
            </div>

            </div>
        }
        
        <div className="flex h-full min-h-max bg-gray-200">

        { loading
            ? <div className="m-auto" >
                <Spinner color='text-red' />
            </div>
            : <div className='mx-auto pt-12'>
            <div className='pb-16 mx-auto '>
                <div className="h-24 p-1 flex justify-center">
                    <img className="h-full" src={locationInfo.companyLogo} alt='logo' />
                </div>
            </div>
            { submitOk 
            ? <div className='grid grid-cols-1 justify-items-center'>
                <div className=''>
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                </div>
                <div className='pt-8 text-center text-2xl'>{t('reservation.success')}</div>
            </div>
            :<>
            <form>
                
                <div className='m-auto'>
                    <div className='flex flex-col space-y-4'>
                       
                        <div key={'location'} className='grid grid-cols-1 gap-2'>    
                            <div className='pb-1 text-sm text-left w-full'>{t('reservation.choose.location')}</div>
                            { 
                            locationInfo.zones && locationInfo.zones.length > 0 && locationInfo.zones.map(z => {
                                return <div className='pt-1 flex justify-between w-full bg-gray-300 rounded-lg h-8 px-3' onClick={() => setValue('zone', z.id)}><div className='font-bold'>{z.name}</div><div><input {...register("zone", { required: true })} name="zone" type="radio" value={z.id} className=''/></div></div> 
                                })
                            } 
                            {errors.zone && <div className='text-red text-center pt-1'>{t('reservation.choose.location')}</div>} 
                        </div>
                        <div key={'name'} className='pt-3'>
                            <div className='pb-1 text-sm'>{t('reservation.choose.name')}</div>
                            <input type="text" placeholder='your name for reservation'  {...register("name", { required: true})} className='w-full h-8 rounded-lg text-lg px-3' />
                        </div>
                        <div className='pt-3'>
                            <div className='pb-1 text-sm'>{t('reservation.choose.guestAmount')}</div>
                            <input type="number" {...register("guests", { valueAsNumber: true})} className='text-center rounded-lg w-full text-lg' />
                        </div>
                        <div key={'email'} className='pt-3'>
                            <div>
                                <div className='pb-1 text-sm'>{t('reservation.choose.email')}</div>
                                <input type="email" placeholder='your email for confirmation' {...register('email', { required: true })} className='w-full h-8 rounded-lg text-lg px-3'/>
                            </div>
                            {errors.email && <div className='text-red text-center pt-1'>Email or Phone is required.</div>}
                        </div>
                       
                        <div key={'date'}>
                            <div>
                                <div className='pb-1 text-sm'>{t('reservation.choose.date')}</div>
                                <input type="date" {...register('date', { required: true })}  className='w-full rounded-lg text-lg px-3'/>
                            </div>
                        </div>
                        <div key={'time'}>
                            <div>
                                <div className='pb-1 text-sm'>{t('reservation.choose.time')}</div>
                                <input type="time" {...register('time', { required: true })}  className='w-full rounded-lg text-lg px-3'/>
                            </div>
                        </div>
                        <div key={'comment'} className='pt-3 pb-20'>
                            <div>
                                <div className='pb-1 text-sm'>{t('reservation.choose.comment')}</div>
                                <textarea placeholder='any comments' {...register('comment', { required: false })} className='w-full rounded-lg text-lg px-3'/>
                            </div>
                        </div>
                    </div>
                </div>
             
            </form>
           
            <div className='block fixed inset-x-0 bottom-0'>
                <button className='bg-red w-full h-12 focus:outline-none text-white lg:text-xl text-lg font-bold' onClick={handleSubmit(onSubmit)}>Reserve</button>
            </div>
            </> 
            }
            
            
        </div>
            
        } 

            
          
        </div>
    </>;


}