import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createRoot, hydrateRoot } from 'react-dom/client';

import './index.css';
import i18n from './i18n';

import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import Home from './components/Home';
import TableReservation from './components/TableReservation';
import './App.css';

const container = document.getElementById('root');
const root = createRoot(container); 

if (container.hasChildNodes()) {
  hydrateRoot(container, <BrowserRouter>
    <Routes>
      <Route path="/" element={<I18nextProvider i18n={i18n}><Home /></I18nextProvider>} />
      <Route path="/reservation/:locationId" element={<I18nextProvider i18n={i18n}><TableReservation /></I18nextProvider>} />
    </Routes>
  </BrowserRouter>);
} else {
  root.render(<BrowserRouter>
    <Routes>
      <Route path="/" element={<I18nextProvider i18n={i18n}><Home /></I18nextProvider>} />
      <Route path="/reservation/:locationId" element={<I18nextProvider i18n={i18n}><TableReservation /></I18nextProvider>} />
    </Routes>
  </BrowserRouter>);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
