import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend";
import common_ru from "./translations/ru/common.json";
import common_ee from "./translations/ee/common.json";
import common_en from "./translations/en/common.json";
import common_ng from "./translations/ng/common.json";

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'ru',                              // language to use
    saveMissing: true,
    resources: {
        ng: {
          common: common_ng               // 'common' is our custom namespace
        },
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        ee: {
            common: common_ee
        },
        ru: {
          common: common_ru
      },
    },
    backend: {
      loadPath: '/translations/{{lng}}/common.json',
    },
});

  export default i18n;